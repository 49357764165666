import React from "react";
import { Link, useLocation } from "react-router-dom";

export const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <aside
      className={`h-full transition-transform duration-300 ${isOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 w-80 fixed md:relative z-50`}
    >
      <div className="h-[-webkit-fill-available] p-5 m-4 bg-white border border-base-300 shadow-xl rounded-xl">
        <div className="font-bold flex items-center gap-2">
          <button
            className="btn btn-ghost md:hidden"
            onClick={toggleSidebar}
            aria-label="Toggle Sidebar"
          >
            <span className="material-symbols-outlined text-base">close</span>
          </button>
          <span className="text-[#1f3745] text-base font-normal md:text-2xl flex items-center gap-2">
            Last CRM
            <div className="badge badge-secondary self-start">beta</div>
          </span>
        </div>
        <ul className="mt-4">
          <button
            className="btn btn-primary"
            onClick={() => document.getElementById("ask_question").showModal()}
          >
            Ask Last CRM 💫
          </button>
          <li className="py-2">
            <Link
              to="/"
              className={`block p-2 rounded-lg hover:bg-secondary hover:text-secondary-content ${isActive("/") ? "bg-base-200" : ""}`}
            >
              Home
            </Link>
          </li>
          <li className="py-2">
            <Link
              to="/customers"
              className={`block p-2 rounded-lg hover:bg-secondary hover:text-secondary-content ${isActive("/customers") ? "bg-base-200" : ""}`}
            >
              Customers
            </Link>
          </li>
          <li className="py-2">
            <Link
              to="/settings"
              className={`block p-2 rounded-lg hover:bg-secondary hover:text-secondary-content ${isActive("/settings") ? "bg-base-200" : ""}`}
            >
              Settings
            </Link>
          </li>
          <li className="py-2">
            <Link
              to="/profile"
              className={`block p-2 rounded-lg hover:bg-secondary hover:text-secondary-content ${isActive("/profile") ? "bg-base-200" : ""}`}
            >
              Profile
            </Link>
          </li>
          <li className="py-2">
            <Link
              to="/login"
              className={`block p-2 rounded-lg hover:bg-secondary hover:text-secondary-content ${isActive("/login") ? "bg-base-200" : ""}`}
            >
              Logout
            </Link>
          </li>
        </ul>
        <dialog id="ask_question" className="modal">
          <div className="modal-box">
            <h3 className="font-bold text-lg">
              Search within your customer data
            </h3>
            <textarea
              className="textarea bg-base-200 w-full"
              placeholder="Has John Smith signed his document?"
            ></textarea>
            <div className="modal-action">
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn mr-4">Close</button>
                <button className="btn btn-primary">Ask</button>
              </form>
            </div>
          </div>
        </dialog>
      </div>
    </aside>
  );
};
