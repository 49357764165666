import React from "react";
import { customers } from "../data/customers";
import { Table } from "../components/table";

export const Home = () => {
  return (
    <div>
      <div className="card border border-base-300 bg-white mb-4 w-full">
        <div className="card-body">
          <h2 className="card-title">
            <span className="material-symbols-outlined">search</span>
            Search within your customer data
          </h2>
          <textarea
            className="textarea"
            placeholder="Has John Smith signed his document?"
          ></textarea>
          <div className="card-actions justify-end">
            <button className="btn btn-primary">Ask</button>
          </div>
        </div>
      </div>

      <div className="stats stats-vertical md:stats-horizontal shadow bg-white w-full mb-2">
        <div className="stat">
          <div className="stat-title">Customers</div>
          <div className="stat-value">10</div>
          <div className="stat-desc">Jan 1st - Feb 1st</div>
        </div>

        <div className="stat">
          <div className="stat-title">Time saved with AI</div>
          <div className="stat-value">21 min</div>
          <div className="stat-desc">↗︎ 10 (22%)</div>
        </div>
      </div>

      <div className="stats stats-vertical md:stats-horizontal shadow bg-white w-full">
        <div className="stat">
          <div className="stat-title">AI questions asked</div>
          <div className="stat-value">85</div>
          <div className="stat-desc">↗︎ 10 (22%)</div>
        </div>

        <div className="stat">
          <div className="stat-title">Files uploaded</div>
          <div className="stat-value">123</div>
          <div className="stat-desc">↘︎ 1 (14%)</div>
        </div>
      </div>

      <hr className="my-12" />

      <h3 className="font-semibold mb-2 ml-2 flex gap-2">
        <span className="material-symbols-outlined">person_search</span>
        Lastest customers
      </h3>

      <Table
        data={customers.slice(0, 5)}
        columns={[
          { accessor: "name", label: "Name" },
          { accessor: "job", label: "Job" },
          { accessor: "favoriteColor", label: "Favorite Color" },
        ]}
        onRowClick={(id, navigate) => navigate(`/customers/${id}`)}
      />
    </div>
  );
};
