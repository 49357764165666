import React from "react";
import { customers } from "../data/customers.js";
import { Table } from "../components/table.js";

export const Customers = () => {
  return (
    <div className="card bg-base-200 p-5">
      <div className="flex justify-between mb-2">
        <h2 className="card-title">Customers</h2>
        <button
          className="btn btn-secondary"
          onClick={() => document.getElementById("add_customer").showModal()}
        >
          <span className="material-symbols-outlined">add</span>Add customer
        </button>
      </div>
      <div className="overflow-x-auto rounded-xl">
        <Table
          data={customers}
          columns={[
            { accessor: "name", label: "Name" },
            { accessor: "job", label: "Job" },
            { accessor: "favoriteColor", label: "Favorite Color" },
          ]}
          onRowClick={(id, navigate) => navigate(`/customers/${id}`)}
        />

        <div className="flex w-full p-2 border-t border-t-base-300 justify-center">
          <div className="join">
            <button className="join-item bg-white btn">«</button>
            <button className="join-item bg-white btn">Page 1</button>
            <button className="join-item bg-white btn">»</button>
          </div>
        </div>
      </div>

      <dialog id="add_customer" className="modal">
        <div className="modal-box">
          <div className="flex flex-col gap-2">
            <h3 className="font-bold text-lg">Add Customer</h3>
            <label className="input input-bordered flex items-center gap-2">
              Name
              <input type="text" className="grow" placeholder="Daisy" />
            </label>

            <label className="input input-bordered flex items-center gap-2">
              Email
              <input
                type="email"
                className="grow"
                placeholder="daisy@site.com"
              />
            </label>

            <label className="input input-bordered flex items-center gap-2">
              Job title
              <input
                type="email"
                className="grow"
                placeholder="daisy@site.com"
              />
            </label>

            <select className="select select-bordered w-full">
              <option disabled selected>
                Company
              </option>
              <option>Han Solo</option>
              <option>Greedo</option>
            </select>

            <input
              type="file"
              className="file-input file-input-bordered w-full"
            />
          </div>
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn mr-4">Cancel</button>
              <button className="btn btn-primary">Save</button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
};
