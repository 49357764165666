import React, { useState } from "react";
import { Sidebar } from "./sidebar";
import { Header } from "./header";

export const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen bg-base-100">
      {/* Conditionally apply classes based on sidebar state */}
      <Sidebar toggleSidebar={toggleSidebar} isOpen={isSidebarOpen} />
      <div className="flex-1 flex flex-col">
        <Header toggleSidebar={toggleSidebar} />
        <main className="flex-1 p-6 overflow-auto pt-32">{children}</main>
      </div>
    </div>
  );
};
