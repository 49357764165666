import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <div className="breadcrumbs p-0 text-sm">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          return (
            <li key={to}>
              {isLast ? (
                <span>{value.charAt(0).toUpperCase() + value.slice(1)}</span>
              ) : (
                <Link to={to}>
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const Header = ({ toggleSidebar }) => {
  return (
    <header className="border-b border-b-base-300 text-primary-content p-4 pb-2 flex justify-between items-center backdrop-blur-2xl fixed z-40 left-0 md:left-80 right-0 top-0">
      <div className="flex">
        <button
          className="btn btn-ghost md:hidden mr-2"
          onClick={toggleSidebar}
          aria-label="Toggle Sidebar"
        >
          <span className="material-symbols-outlined text-base">menu</span>
        </button>
        <div>
          <h1 className="text-xl">Header Title</h1>
          <Breadcrumbs />
        </div>
      </div>
      <div className="flex items-center">
        <button className="btn btn-ghost">Login</button>
      </div>
    </header>
  );
};
