import React from "react";
import { useParams } from "react-router-dom";
import { customers } from "../data/customers";

export const Customer = () => {
  const { id: urlId } = useParams();
  const customer = customers.find(({ id }) => id === urlId);
  return (
    <div className="card bg-base-200 p-5">
      <h2 className="card-title">Customer</h2>
      <p>
        <strong>ID:</strong> {customer.id}
      </p>
      <p>
        <strong>Name:</strong> {customer.name}
      </p>
      <p>
        <strong>Jon:</strong> {customer.job}
      </p>
    </div>
  );
};
