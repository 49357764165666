import React from "react";

export const Settings = () => {
  return (
    <div className="card bg-base-200 p-5">
      <h2 className="card-title">Settings</h2>
      <p>Settings options go here.</p>
    </div>
  );
};
