import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./pages/home";
import { Profile } from "./pages/profile";
import { Settings } from "./pages/settings";
import { Layout } from "./components/layout";
import { Login } from "./pages/login"; // Assuming you have a Login component
import { Customers } from "./pages/customers";
import { Customer } from "./pages/customer";

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for login page, not nested under Layout */}
        <Route path="/login" element={<Login />} />

        {/* Nested routes under Layout */}
        <Route
          path="/*"
          element={
            <Layout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/customers/:id" element={<Customer />} />
                <Route path="/settings" element={<Settings />} />
              </Routes>
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
