import React from "react";

export const Profile = () => {
  return (
    <div className="card bg-base-200 p-5">
      <h2 className="card-title">Profile</h2>
      <p>Your profile information goes here.</p>
    </div>
  );
};
