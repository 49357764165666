export const customers = [
  {
    id: "e8b0b0d1-2a62-40f9-9f9b-15e0f31f5a1b",
    name: "Cy Ganderton",
    job: "Quality Control Specialist",
    favoriteColor: "Blue",
  },
  {
    id: "d91b3a55-9d59-4845-b72c-7ae1b25e6f3e",
    name: "Hart Hagerty",
    job: "Desktop Support Technician",
    favoriteColor: "Purple",
  },
  {
    id: "8aadc33e-03b8-4e0b-b4d6-3d4f2dcdcd89",
    name: "Brice Swyre",
    job: "Tax Accountant",
    favoriteColor: "Red",
  },
  {
    id: "1d21b7f9-b94b-4c6b-8307-df481a60b4d1",
    name: "Marianne Osullivan",
    job: "Project Manager",
    favoriteColor: "Green",
  },
  {
    id: "5a5e6c29-0c10-49d3-b4e4-0c6cdb4f3b4d",
    name: "Mason Cohen",
    job: "Software Engineer",
    favoriteColor: "Yellow",
  },
  {
    id: "c9e3a437-bc65-4a4b-82c9-5d3b6d9eeb35",
    name: "Eleanor Pearson",
    job: "UX Designer",
    favoriteColor: "Pink",
  },
  {
    id: "b07e01db-93ee-4e7b-a5c8-70f5ff29c4bb",
    name: "Frankie Ortega",
    job: "Data Analyst",
    favoriteColor: "Orange",
  },
  {
    id: "f20b4bb1-27bb-47c7-8bb7-8f4b8d2a118d",
    name: "Serena Waters",
    job: "Marketing Coordinator",
    favoriteColor: "Teal",
  },
  {
    id: "72a6f19e-5796-4b3e-ae5d-4ab5f0c84d64",
    name: "Aidan Russell",
    job: "Network Administrator",
    favoriteColor: "Violet",
  },
  {
    id: "b8c9d6f4-fad3-4c11-b0f3-bb7b6058ed57",
    name: "Leah Walker",
    job: "Human Resources Manager",
    favoriteColor: "Gold",
  },
  {
    id: "a4f8c7e0-3ebf-4c79-a9f4-b7b3d63f9c77",
    name: "Grant Miles",
    job: "Business Analyst",
    favoriteColor: "Silver",
  },
  {
    id: "d03e1c6f-cc9d-4031-9c19-49f5e2d5bf0d",
    name: "Chloe Dean",
    job: "Content Strategist",
    favoriteColor: "Magenta",
  },
  {
    id: "bfc9f5b1-4248-4951-b9ad-b76c60f8c7a4",
    name: "Jonas Schneider",
    job: "Operations Manager",
    favoriteColor: "Chartreuse",
  },
  {
    id: "9e4c6587-d028-4573-a1a2-bb8e173d7f02",
    name: "Sophie Nguyen",
    job: "Art Director",
    favoriteColor: "Lavender",
  },
  {
    id: "a6f839e5-e567-4e68-b56c-964cc7d5be9c",
    name: "Logan Perry",
    job: "SEO Specialist",
    favoriteColor: "Cyan",
  },
  {
    id: "ae5d59a6-2b7d-4b9f-a377-1bb4e4a19c90",
    name: "Julia Blake",
    job: "Financial Advisor",
    favoriteColor: "Beige",
  },
  {
    id: "c1c3d8a2-c9d2-4e6f-8d8b-9a4f7f3b4e3c",
    name: "Oliver Clarke",
    job: "Cybersecurity Analyst",
    favoriteColor: "Maroon",
  },
  {
    id: "d6f4c8b0-9e64-4a1f-913c-77c0d8b1d3b5",
    name: "Eliza White",
    job: "Legal Consultant",
    favoriteColor: "Turquoise",
  },
  {
    id: "a1b4c7e2-4f9d-4c6b-8b3e-7e3d6f5b8a9c",
    name: "Henry Scott",
    job: "Technical Writer",
    favoriteColor: "Amber",
  },
  {
    id: "d8e4a1b7-2f3c-4e5d-9168-2b9d3c4e5b6a",
    name: "Alice Moore",
    job: "Event Planner",
    favoriteColor: "Coral",
  },
];
