import React from "react";
import { useNavigate } from "react-router-dom";

export const Table = ({ title, data, columns, onRowClick }) => {
  const navigate = useNavigate();

  return (
    <>
      {title}
      <div className="overflow-x-auto bg-white rounded-xl border border-base-300 hidden md:block">
        <table className="table">
          {/* head */}
          <thead>
            <tr>
              <th></th>
              {columns.map((col) => (
                <th key={col.accessor}>{col.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr
                className="hover cursor-pointer"
                key={row.id}
                onClick={() => onRowClick(row.id, navigate)}
              >
                <th>{index + 1}</th>
                {columns.map((col) => (
                  <td key={col.accessor}>{row[col.accessor]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="w-full flex flex-col gap-2 md:hidden">
        {data.map((row) => (
          <div
            className="card border border-base-300 bg-white w-full cursor-pointer hover:bg-base-100 hover:shadow"
            key={row.id}
            onClick={() => onRowClick(row.id, navigate)}
          >
            <div className="card-body">
              <h3 className="card-title">{row.name}</h3>
              {columns.map((col) => (
                <p key={col.accessor}>
                  <strong>{col.label}:</strong> {row[col.accessor]}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
